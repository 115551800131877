import { template as template_c43dd07cd5fa483da69960e0d374d0c7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c43dd07cd5fa483da69960e0d374d0c7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
