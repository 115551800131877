import { template as template_8defb295006749ec88a2294ac86d50a3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_8defb295006749ec88a2294ac86d50a3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
