import { template as template_2f38c081172748458f1f7f254cdf6889 } from "@ember/template-compiler";
const FKLabel = template_2f38c081172748458f1f7f254cdf6889(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
