import { template as template_94a41d15e1a641b4988b17bf7a2ec358 } from "@ember/template-compiler";
const SidebarSectionMessage = template_94a41d15e1a641b4988b17bf7a2ec358(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
